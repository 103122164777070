import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReport: (ctx, q) => axiosIns.get(`/api.report${q || ''}`),
    fetchReportGeneral: (ctx, q) => axiosIns.get('/api.report/collection/dashboard?type=detail'),
    createEmbed: (ctx, q) => axiosIns.post('/api.report/collection/dashboard/create-embed', q),
  },
}
